import Axios from "./axios";

const GetAllUsersAPI = async (onSuccess, onError) => {
  try {
    const response = await Axios.get("/api/user/get_all_user_data_test", {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    onSuccess && onSuccess(response);
    // debugger;
  } catch (err) {
    console.log("got an error", err);
    onError && onError(err);
  }
};


const UpdateUserStatusAPI = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.put("/api/user/update_user_status", data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    onSuccess && onSuccess(response);
    // debugger;
  } catch (err) {
    console.log("got an error", err);
    onError && onError(err);
  }
};

const SendVerificationEmail = async (data, onSuccess, onError) => {
  try {
    const response = await Axios.post("/api/user/resendEmailVerification", data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    onSuccess && onSuccess(response);
    // debugger;
  } catch (err) {
    console.log("got an error", err);
    onError && onError(err);
  }
};

export { UpdateUserStatusAPI, GetAllUsersAPI, SendVerificationEmail };